@import './styles/global.css';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

.englobe-panier-listpizzas {
  display: flex;
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}
