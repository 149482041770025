.footer {
  background-color: var(--secondary-color);
  color: white;
  padding: 40px 20px;
  text-align: center;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.footer-link {
  color: white;
  text-decoration: none;
  transition: var(--transition);
}

.footer-link:hover {
  color: var(--primary-color);
}

.footer-social {
  margin-top: 20px;
}

.footer-social a {
  color: white;
  margin: 0 10px;
  font-size: 1.5em;
  transition: var(--transition);
}

.footer-social a:hover {
  color: var(--primary-color);
}

.footer-copyright {
  margin-top: 30px;
  font-size: 0.9em;
  color: rgba(255,255,255,0.7);
}
