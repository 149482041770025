.panier-container {
  position: fixed;
  top: 123px; /* Positionné juste en dessous du header de 123px */
  right: 0;
  width: 400px;
  height: calc(100vh - 123px); /* Ajusté pour correspondre au nouveau top */
  background: white;
  box-shadow: -4px 0 12px rgba(0, 0, 0, 0.15);
  transform: translateX(100%);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1000;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.panier-container.open {
  transform: translateX(0);
}

@media (max-width: 768px) {
  .panier-container {
    width: 100%;
    box-shadow: none;
    transform: translateX(100%);
    top: 0;
    height: 100vh;
  }

  .panier-container.open {
    transform: translateX(0);
  }
  
  .panier-header {
    padding: 15px;
  }
  
  .panier-header h2 {
    font-size: 1.3rem;
  }
  
  .panier-items {
    padding: 15px;
  }
  
  .panier-item {
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
  
  .item-info h3 {
    font-size: 1rem;
  }
  
  .item-info p {
    font-size: 0.9rem;
  }
  
  .panier-footer {
    padding: 15px;
  }
  
  .total {
    font-size: 1rem;
  }
  
  .checkout-btn {
    padding: 12px;
    font-size: 0.9rem;
  }
}

.cart-count {
  position: absolute;
  top: -8px;
  right: -8px;
  background: #ff5a5f;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  animation: pop 0.3s ease;
}

@keyframes pop {
  0% { transform: scale(0); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.panier-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.panier-header {
  padding: 20px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.panier-header h2 {
  margin: 0;
  font-size: 1.5rem;
}

.close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 5px;
  transition: transform 0.2s ease;
}

.close-btn:hover {
  transform: rotate(90deg);
}

.panier-items {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.panier-item {
  margin-bottom: 10px;
  padding: 10px;
  border-bottom: 1px solid #eee;
  display: grid;
  grid-template-columns: 60px 1fr auto;
  gap: 10px;
  align-items: center;
  background: #f8f8f8;
  border-radius: 8px;
}

.panier-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.cart-item-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 6px;
}

.item-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.supp-ingredients {
  grid-column: 1 / -1;
  margin: 5px 0 0 0;
  padding: 10px;
  background: white;
  border-radius: 6px;
  font-size: 0.9em;
}

.supplement-list {
  width: 100%;
  overflow: hidden;
}

.supplement-list li {
  display: flex;
  justify-content: space-between;
  padding: 2px 0;
  font-size: 0.8em;
  color: #666;
  white-space: nowrap;
}

.supplement-list li span:first-child {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.supplement-list li span:last-child {
  flex: 0 0 60px;
  text-align: right;
  padding-right: 10px;
}

.panier-footer {
  padding: 20px;
  border-top: 1px solid #eee;
}

.total {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 1.1rem;
  font-weight: bold;
}

.checkout-btn {
  width: 100%;
  padding: 15px;
  background: #ff5a5f;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.2s;
}

.checkout-btn:hover {
  background: #e04a4f;
}
