.header {
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
  position: sticky;
  top: 0;
  z-index: 100;
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .header {
    padding: 0.5rem;
  }
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .header-content {
    padding: 0 0.5rem;
  }
}

.logo img {
  height: 100px;
}

.main-nav {
  flex: 1;
  margin: 0 2rem;
}

.main-nav ul {
  display: flex;
  justify-content: center;
  gap: 2rem;
  list-style: none;
  margin: 0;
  padding: 0;
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .main-nav {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 250px;
    background: white;
    margin: 0;
    padding: 1rem;
    box-shadow: -2px 0 5px rgba(0,0,0,0.1);
    transition: right 0.3s ease;
  }

  .main-nav.active {
    right: 0;
  }

  .main-nav ul {
    flex-direction: column;
    gap: 1rem;
    margin-top: 60px;
  }
}

.hamburger {
  display: none;
  cursor: pointer;
  padding: 0.5rem;
}

@media (max-width: 768px) {
  .hamburger {
    display: block;
  }
}

.main-nav a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
  transition: all 0.2s ease;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  display: inline-block;
}

@media (max-width: 768px) {
  .main-nav a {
    padding: 0.5rem;
    font-size: 0.9rem;
  }
}

.main-nav a:hover {
  color: #ff5a5f;
}

.main-nav .active {
  color: #ff5a5f;
  background: rgba(255, 90, 95, 0.1);
}

.cart-icon {
  position: relative;
  cursor: pointer;
  padding: 0.5rem;
}

.cart-icon svg {
  width: 30px;
  height: 30px;
  fill: #333;
  transition: fill 0.2s;
}

.cart-icon:hover svg {
  fill: #ff5a5f;
}

@keyframes pop {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.cart-count {
  position: absolute;
  top: -5px;
  right: -5px;
  background: #ff5a5f;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: bold;
  animation: pop 0.3s ease-in-out;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}
