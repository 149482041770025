.cde-fond-ecran {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cde-content {
  background: white;
  padding: 1rem;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  height: 90vh;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow-y: auto;
}

.cde-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.75rem;
  text-align: center;
}

.cde-price {
  font-size: 1.2rem;
  color: #333;
  text-align: center;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.cde-price .base-price {
  color: #27ae60;
  font-weight: bold;
}

.cde-price .supplement-price {
  color: #e67e22;
  font-size: 1.2rem;
}

.cde-price .total-price {
  color: #e74c3c;
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 0.5rem;
}

.composition-section {
  margin: 20px 0;
  padding: 15px;
  background: #f8f8f8;
  border-radius: 8px;
}

.composition-section h3 {
  margin-bottom: 10px;
  color: #333;
  font-size: 1.2em;
}

.cde-ingredient {
  font-size: 1.1rem;
  color: #444;
  line-height: 1.6;
  margin: 1rem 0;
  padding: 1rem;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #eee;
  max-height: 200px;
  overflow-y: auto;
}

.cde-ingredient br {
  display: none;
}

.cde-ingredient li {
  padding: 0.5rem 0;
}

.cde-ingredient li:nth-child(n+6) {
  display: none;
}

.cde-ingredient.show-all li {
  display: block;
}

.cde-body {
  margin: 20px 0;
}

.supplements-section {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
}

.supplements-section h3 {
  margin-top: 0;
  color: #333;
}

.supplements-list {
  max-height: 200px;
  overflow-y: auto;
  padding-right: 10px;
}

.supplement-item {
  margin: 5px 0;
  padding: 5px;
  border-bottom: 1px solid #eee;
}

.supplement-item:last-child {
  border-bottom: none;
}

.selected-supplements {
  margin-top: 15px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 3px;
}

.selected-supplements h4 {
  margin: 0 0 10px 0;
  color: #555;
}

.selected-supplements ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.selected-supplements li {
  padding: 5px 0;
  color: #666;
}

.selected-supplements p {
  margin: 5px 0;
  color: #666;
}

.selected-supplements .total-price {
  margin-top: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #e74c3c;
}

.cde-footer {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  padding: 20px;
}

.panier-info {
  display: flex;
  align-items: center;
  gap: 20px;
}

.panier-details {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 0.9em;
  color: #333;
}

.panier-details span {
  font-weight: 500;
}

.btn {
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.btn.bleu {
  background-color: #3498db;
  color: white;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.btn.bleu:hover {
  background-color: #2980b9;
}

.btn.vert {
  background-color: #2ecc71;
  color: white;
}

.btn.vert:hover {
  background-color: #27ae60;
}

.checkbox-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.checkbox-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.checkbox-item input[type="checkbox"] {
  width: 1.2rem;
  height: 1.2rem;
}

.checkbox-item label {
  font-size: 1rem;
  color: #333;
}
