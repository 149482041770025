.type-pizza {
  display: grid;
  grid-template-columns: 150px 1fr 200px;
  gap: 30px;
  margin: 10px auto;
  padding: 25px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  width: 100%;
  min-height: 220px;
  position: relative;
}

.type-pizza__image {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  object-fit: cover;
  align-self: center;
}

.type-pizza__content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.type-pizza__content h3 {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}

.info-icon {
  color: var(--color-primary);
  cursor: help;
  transition: color 0.2s ease;
}

.info-icon:hover {
  color: var(--color-secondary);
}

.type-pizza__content p {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 0;
}

.pizza-actions {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-end;
  justify-content: center;
  padding: 15px;
}

.type-pizza h3 {
  margin: 5px;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  gap: 10px;
}

.type-pizza h3 .price {
  font-size: 1.2rem;
  color: var(--color-secondary);
  font-weight: bold;
}

.type-pizza .pizza-type {
  position: absolute;
  top: 10px;
  right: 10px;
  background: var(--color-primary);
  color: white;
  padding: 8px 15px;
  border-radius: 20px;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.type-pizza p {

  margin: 0 5px;
  font-size: 0.75rem;
  max-height: 60px;
}

.type-pizza span {

  margin: 5px;
  font-size: 0.9rem;
}

.type-pizza__image {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  object-fit: cover;
  align-self: center;
}

.type-pizza__content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
}

.pizza-actions {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-end;
  justify-content: center;
  padding: 15px;
  width: 100%;
}

.quantity-controls {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.quantity-btn {
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.2s ease;
}

.quantity-btn:hover {
  background-color: #f5f5f5;
}

.quantity-value {
  font-weight: bold;
  min-width: 2rem;
  text-align: center;
}

.add-ingredient-btn {
  padding: 0.75rem 1.5rem;
  background: linear-gradient(135deg, var(--color-secondary) 0%, #ff9a44 100%);
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  display: flex;
  align-items: center;
  gap: 8px;
}

.add-ingredient-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
  background: linear-gradient(135deg, #ff9a44 0%, var(--color-secondary) 100%);
}

.add-to-cart-btn {
  width: 50px;
  height: 50px;
  padding: 0;
  background: var(--color-primary);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.add-to-cart-btn:hover {
  transform: scale(1.1);
  background: var(--color-secondary);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
  position: relative;
}

.close-modal {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: var(--color-primary);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.modal-actions {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.add-to-cart-modal-btn {
  padding: 0.75rem 1.5rem;
  background: var(--color-primary);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1rem;
}

.add-to-cart-modal-btn:hover {
  background: var(--color-secondary);
  transform: translateY(-2px);
}

.close-modal:hover {
  background: var(--color-secondary);
}

.supplements-list {
  margin: 1.5rem 0;
  max-height: 400px;
  overflow-y: auto;
  padding-right: 1rem;
}

.supplement-item {
  padding: 0.8rem;
  margin: 0.5rem 0;
  background: #f8f8f8;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.supplement-item:hover {
  background: #f0f0f0;
  transform: translateX(5px);
}

.supplement-item label {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}

.supplement-item input[type="checkbox"] {
  width: 18px;
  height: 18px;
  accent-color: var(--color-primary);
  cursor: pointer;
}

@media (max-width: 768px) {
  .supplements-list {
    max-height: 300px;
  }
  
  .supplement-item {
    padding: 0.6rem;
  }
  
  .supplement-item label {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .type-pizza {
    width: 95%;
    margin: 20px auto;
    max-height: none;
  }
  
  .type-pizza__image {
    height: 100px;
  }
  
  .type-pizza h3 {
    font-size: 1.8rem;
    margin: 25px 20px;
  }
  
  .type-pizza p {
    font-size: 1rem;
    margin: 0 25px 25px;
  }
  
  .type-pizza span {
    font-size: 1.5rem;
    margin: 0 25px 25px;
  }
  
  .pizza-actions {
    flex-direction: column;
    gap: 1rem;
    margin: 0 25px 25px;
  }
  
  .add-to-cart-btn {
    width: 100%;
    padding: 1rem;
  }
}
