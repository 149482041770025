.histoire-container {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1rem;
}

.histoire-content {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
}

.histoire-content img {
  width: 50%;
  border-radius: 8px;
  box-shadow: var(--shadow);
}

.histoire-text {
  width: 50%;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .histoire-content {
    flex-direction: column;
  }
  
  .histoire-content img,
  .histoire-text {
    width: 100%;
  }
}
