.about-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.about-title {
  text-align: center;
  color: #c82333;
  margin-bottom: 2rem;
}

.about-content {
  display: grid;
  gap: 2rem;
}

.about-section {
  background: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.about-section h2 {
  color: #333;
  margin-bottom: 1rem;
}

.about-section p {
  color: #666;
  line-height: 1.6;
}

@media (min-width: 768px) {
  .about-content {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .about-content {
    grid-template-columns: repeat(3, 1fr);
  }
}
