.list-pizzas {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.size-filter {
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
}

.size-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  cursor: pointer;
}

.size-select:focus {
  outline: none;
  border-color: #d72638;
}

.size-filter {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}

.allergenes-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #d72638;
  font-size: 16px;
  transition: opacity 0.2s;
  display: flex;
  align-items: center;
  gap: 5px;
}

.allergenes-btn:hover {
  opacity: 0.8;
}

.allergenes-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.allergenes-content {
  background: white;
  border-radius: 8px;
  padding: 2rem;
  max-width: 600px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
}

.allergenes-list {
  margin: 1rem 0;
}

.allergenes-list ol {
  padding-left: 1.5rem;
  margin: 1rem 0;
}

.allergenes-list li {
  margin-bottom: 0.5rem;
}

.important-note {
  margin-top: 1.5rem;
  padding: 1rem;
  background: #fff3f4;
  border-radius: 4px;
  border-left: 4px solid #d72638;
}

.close-btn {
  margin-top: 1.5rem;
  width: 100%;
}

.pizza-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  width: 100%;
}

.pizza-card {
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: flex;
  flex-direction: column;
  height: 450px;
}

.pizza-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
}

.pizza-card__image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 3px solid #d72638;
}

.pizza-card__content {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;
  overflow: auto;
}

.pizza-card__title {
  margin: 0;
  font-size: 1.5rem;
  color: #222;
  font-weight: 600;
}

.pizza-card__description {
  margin: 0;
  color: #444;
  font-size: 1rem;
  line-height: 1.4;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.pizza-card__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  border-top: 1px solid #eee;
}

.pizza-card__price {
  font-weight: bold;
  color: #d72638;
  font-size: 1.25rem;
}

.pizza-card__button {
  background-color: #d72638;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
}

.pizza-card__button:hover {
  background-color: #c12030;
  transform: translateY(-2px);
  box-shadow: 0 3px 8px rgba(215, 38, 56, 0.3);
}

@media (max-width: 768px) {
  .pizza-list {
    grid-template-columns: 1fr;
    padding: 1rem;
  }
  
  .pizza-card {
    margin-bottom: 1rem;
  }
}
