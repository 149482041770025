:root {
  --color-primary: #c0392b;
  --color-secondary: #34495e;
  --color-background: #ffffff;
  --color-text: #2c3e50;
  --border-radius: 8px;
  --shadow: 0 2px 4px rgba(0,0,0,0.1);
  --font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
  font-family: var(--font-family);
  background-color: var(--color-background);
  color: var(--color-text);
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.main-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}


@media (min-width: 768px) {
  .main-grid {
    grid-template-columns: 2fr 1fr;
  }
}

.footer {
  background: var(--secondary-color);
  color: white;
  padding: 1rem;
  text-align: center;
}
