:root {
  --primary-color: #ff6347;
  --secondary-color: #333333;
  --background-color: #f5f5f5;
  --text-color: #333333;
  --light-text-color: #666666;
  --border-color: #dddddd;
  --shadow: 0 4px 12px rgba(0,0,0,0.1);
  --transition: all 0.3s ease;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;

}

a {
  color: var(--primary-color);
  text-decoration: none;
  transition: var(--transition);
}

a:hover {
  color: var(--secondary-color);
}

button {
  border: none;
  border-radius: 8px;
  cursor: pointer;
  padding: 12px 24px;
  font-size: 1em;
  font-weight: 500;
  transition: var(--transition);
}

button.primary {
  background-color: var(--primary-color);
  color: white;
}

button.primary:hover {
  background-color: #c0392b;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}
